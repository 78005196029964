import React, { useEffect, useState } from "react";
import AppContext from "./AppContext";
import questionsArray from "./constants/questionsArray";
import Questions from "./components/Questions";
import { Typography } from "@material-ui/core";

function App() {

  ///Defining UseState
  let [questions, setQuestions] = useState([]);
  let [answers, setAnswers] = useState([/*{
    nameLessor: '',
    nameTenant: ''
  }*/]);
  let [questionAnswer, setQuestionAnswer] = useState({});


  useEffect(() => {
    setQuestions(questionsArray);
    setQuestionAnswer(questionsArray[0]);
  }, []);

  let handleChangeInput = (e) => {
    setQuestionAnswer({
      ...questionAnswer,
      answer: e.target.value,
    });
  };

  //Next Question Function
  let nextQuestion = (e) => {
    e.preventDefault();
    questions.map((question) => {
      if (question.contractFieldId == questionAnswer.contractFieldId) {
        setAnswers([
          ...answers,
          { ...question, answer: questionAnswer.answer },
        ]);
      }
    });

    questions.map((qa, index) => {
      if (index <= questions.length) {
        if (qa.contractFieldId === questionAnswer.contractFieldId) {
          setQuestionAnswer(questions[index + 1]);
        }
      }
    });
  };


  // Previus Question 
  //Next Question Function
  let previewQuestion = (e) => {
    e.preventDefault();
    questions.map((question) => {
      if (question.contractFieldId == questionAnswer.contractFieldId) {
        setAnswers([
          ...answers,
          { ...question, answer: questionAnswer.answer },
        ]);
      }
    });

    questions.map((qa, index) => {
      if (index <= questions.length) {
        if (qa.contractFieldId === questionAnswer.contractFieldId) {
          setQuestionAnswer(questions[index - 1]);
        }
      }
    });
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          questionAnswer,
          questions,
          answers,
        },
        function: {
          handleChangeInput: handleChangeInput,
          nextQuestion: nextQuestion,
        },
      }}
    >
      <div className="App">
        <h2 className="App__title">Generador de Contratos</h2>
        <Questions />
      </div>
    </AppContext.Provider>
  );
}

export default App;
