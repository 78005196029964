//Define the contract default questions

let questionsArray = [
  {
    id: 1,
    question: "Nombre del Arrendador",
    contractField: "NameLessor",
    contractFieldId: "nameLessor",
    type: "text"
  },
  {
    id: 2,
    question: "Nombre del Arrendadatario",
    contractField: "nameTenant",
    contractFieldId: "nameTenant",
    type: "text"
  },
  {
    id: 3,
    question: "Fecha",
    contractField: "Date",
    contractFieldId: "date",
    type:"date"
  },
  {
    id: 4,
    question: "Dirección del inmueble",
    contractField: "Adress",
    contractFieldId: "address",
    type: "text"
  },
  {
    id: 5,
    question: "Clave catastral",
    contractField: "CadastralKey",
    contractFieldId: "cadastralKey",
    type: "text"
  },
  {
    id: 6,
    question: "Vigencia del contrato",
    contractField: "ContractEnds",
    contractFieldId: "contractEnds",
    type: "number"
  },
  {
    id: 7,
    question: "Cantidad mensual a pagar",
    contractField: "MonthlyPayment",
    contractFieldId: "monthlyPayment",
    type: "number"
  },
  {
    id: 8,
    question: "Deposito inicial a pagar",
    contractField: "DepositPayment",
    contractFieldId: "depositPayment",
    type: "number"
  },
  // {
  //   id: 9,
  //   question: "Periodo para reembolsar deposito pagado",
  //   contractField: "DepositRefund",
  //   contractFieldId: "depositRefund",
  //   type: "text"
  // },
  {
    id: 10,
    question: "Días en los que se realizará el pago",
    contractField: "PaymentDay",
    contractFieldId: "paymentDay",
    type: "number"
  },
  // {
  //   id: 11,
  //   question: "Tasa % de interés por pago retrasado ",
  //   contractField: "PaymentLater",
  //   contractFieldId: "paymentLater",
  //   type: "number"
  // },
  // {
  //   id: 12,
  //   question: "Multa por retraso de pago",
  //   contractField: "PaymentPenalty",
  //   contractFieldId: "paymentPenalty",
  //   type: "number"
  // },
  
  {
    id: 12,
    question: "Testigo del Arrendador",
    contractField: "witnessTenant",
    contractFieldId: "witnessTenant",
    type: "text"
  },
  {
    id: 13,
    question: "Testigo del Arrendadoratario",
    contractField: "witnessLessor",
    contractFieldId: "witnessLessor",
    type: "text"
  },

];
export default questionsArray;
