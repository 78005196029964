import React, { createRef, useContext } from "react";
import Pdf from "react-to-pdf";
import AppContext from "../AppContext";
import { makeStyles } from "@material-ui/core/styles";
// import { Search } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  buttonBuildNew: {
    cursor: "pointer",
    minWidth: "7rem",
    textAlign: "center",
    border: "none",
    padding: "1rem",
    boxShadow: "0 1px 1px 0 rgb(0 0 0 / 20%), 0 1px 2px 0 rgb(0 0 0 / 19%)",
    "&:hover": {
      background: "#d6d6d6",
    },
  },
  buttonDownload: {
    cursor: "pointer",
    minWidth: "7rem",
    textAlign: "center",
    border: "none",
    padding: "1rem",
    boxShadow: "0 1px 1px 0 rgb(0 0 0 / 20%), 0 1px 2px 0 rgb(0 0 0 / 19%)",
    background: "#77bb77",
    "&:hover": {
      background: "#548854",
    },
  },
  contract: {
    padding: "3rem",
    boxShadow: "0 0.5rem 1rem 0 rgb(0 0 0 / 10%)",
    marginBottom: "1rem",
    margin: "1rem",
    marginTop: "3rem",
  },
}));

let refreshPage = () => {
  window.location.reload();
};

function Contract() {
  const ref = createRef();
  const value = useContext(AppContext);
  const classes = useStyles();

  let { answers } = value.state;


  



  return (
    <div>
      <div ref={ref} className={classes.contract}>

         {/* {answers.map((answer) => {
          return (
            <div>
              {answer.contractFieldId === "nameLessor" ||
              answer.contractFieldId === "nameTenant" ||
              answer.contractFieldId === "address" ||
              answer.contractFieldId === "date" ? (
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <label>{answer.answer}</label>
                </div>
              ) : (
                <div>
                  <h4>{answer.contractField}</h4>
                  <p>{answer.answer}</p>
                </div>
              )}
              
            </div>
            
          );
        })}  */}



        {/* {answers.map((answer) => {
          return (
            <div>
             
                <div>
                  <h4>{answers[0].answer}</h4>
                 
                </div>
              
              
            </div>
            
          );
        })}  */}

        {/* INICIO CONTRATO */}
        <p>
        CONTRATO DE <strong>ARRENDAMIENTO</strong> QUE CELEBRAN, POR UNA PARTE Y POR SU PROPIO DERECHO, {answers.filter(answer => answer.id === 1).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}, QUIEN EN LO SUCESIVO Y PARA LOS EFECTOS DEL PRESENTE CONTRATO SERÁ DENOMINADO COMO "<strong>ARRENDADOR</strong>"; Y POR LA OTRA, {answers.filter(answer => answer.id === 2).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}, POR SU PROPIO DERECHO Y A QUIEN EN LO SUCESIVO Y PARA  LOS EFECTOS DEL PRESENTE CONTRATO SE LE DENOMINARÁ  "ARRENDATARIO", DECIDIENDO OBLIGARSE AL TENOR DE LAS SIGUIENTES:
        </p>

        {/* DECLARACIONES */}

        <center><h3>D E C L A R A C I O N E S.</h3></center>

          {/* DECLARACIÓN DEL "ARRENDADOR" */}

        <strong><h3>A.-  DECLARA EL "ARRENDADOR" QUE: </h3></strong>


        <p>
        <strong>I)</strong> 	Es una persona física, mayor de edad que cuenta con la capacidad jurídica para celebrar el presente Contrato.
        <br/><br/>
        <strong>II)</strong>	Es el legítimo propietario y pacífico poseedor del bien inmueble ubicado en  {answers.filter(answer => answer.id === 4).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}; , <strong>en el estado de Baja California</strong>; mismo que cuenta con clave catastral {answers.filter(answer => answer.id === 5).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })} y que en lo sucesivo y para todos los efectos del presente contrato será denominado como <strong>“INMUEBLE”</strong>
        <br/><br/>
        <strong>III)</strong>	Que es su intención el otorgar el uso temporal del <strong>“INMUEBLE”</strong> antes descrito, a cambio del pago de una renta fija que será precisada dentro del presente contrato.
        <br/><br/>
        <strong></strong>IV)	Tiene por domicilio para oír y recibir notificaciones relativas al presente contrato el ubicado en  {answers.filter(answer => answer.id === 4).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}
        </p>

        {/* DECLARACIÓN DEL "ARRENDATARIO" */}

        <strong><h3>B.- 	DECLARA EL “ARRENDATARIO” QUE: </h3></strong>

        <p>
          I)    Es una persona física, mayor de edad, que cuenta con la capacidad jurídica para celebrar el presente Contrato.
          <br/><br/>
        II)   Que es su voluntad recibir el uso temporal del <strong>“INMUEBLE”</strong> en concepto de arrendamiento, con la finalidad de utilizarlo con fines meramente <strong>habitacionales</strong>, otorgando al <strong>"ARRENDADOR"</strong> el pago de la contraprestación prevista dentro del presente.
          <br/><br/>
          III)    Que conoce a la perfección el inmueble materia del arrendamiento, por lo que lo recibe en las condiciones en las que se encuentra, manifestando estar conforme con las mismas.
          <br/><br/>
          IV)   Tener por domicilio para oír y recibir notificaciones relativas al presente contrato el ubicado en {answers.filter(answer => answer.id === 4).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}; es decir, en el <strong>INMUEBLE</strong>

        </p>

        {/* INSERTAR CONDICIONAL EN CASO DE QUE EL PACIENTE TENGA AVAL */}

        <strong><h3>III.- DECLARAN LAS PARTES: </h3></strong>

        <p>
        <strong>I)</strong>.- Que consienten que todos las cláusulas y términos de este contrato son claros y no dejan duda sobre la intención que los ha llevado a celebrarlo.
          <br/><br/>
          <strong>II)</strong>.- Ambas partes manifiestan que toda convención que quede consagrada en el presente contrato, es lícita, por lo tanto todas las obligaciones y acciones generadas a partir de su suscripción, producirán efectos sin perjuicio alguno.
          <br/><br/>
          <strong>III)</strong>.- Que reconocen la <strong>solidaridad pasiva</strong> existente entre el <strong>“ARRENDATARIO”</strong> y el <strong>“OBLIGADO SOLIDARIO”</strong> con respecto a las obligaciones pactadas a favor del “ARRENDADOR” dentro del presente instrumento, por lo que aquellos se obligan solidariamente a dar cumplimiento a todas aquellas obligaciones pactadas a favor de este último.

          <br/><br/>
          <strong>IV)</strong>.- Que de conformidad a las declaraciones descritas, es de su interés celebrar el presente contrato, de conformidad a las siguientes:
        
        </p>

        {/* CLAUSULAS */}

        <strong><center><h3>C L Á U S U L A S.</h3></center></strong>

        <p>
        {/* PRIMERA.- OBJETO */}
        <strong>PRIMERA.- OBJETO.</strong> Por virtud del presente Contrato de Arrendamiento, el <strong>"ARRENDADOR"</strong> concede el uso temporal del <strong>“INMUEBLE”</strong>; mismo que se encuentra ubicado en {answers.filter(answer => answer.id === 4).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}; 
        y que cuenta con clave catastral {answers.filter(answer => answer.id === 5).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })};, en concepto arrendamiento, a favor del <strong>“ARRENDATARIO”</strong>, quien en este mismo acto manifiesta recibirlo de conformidad y a su entera satisfacción. 

        </p>

        {/* INSERTAR CONDICIONAL DE INVENTARIO DE MUEBLES */}

        <p>
        {/* SEGUNDA.- VIGENCIA. */}
        <strong>SEGUNDA.- VIGENCIA.</strong> El presente contrato tendrá una vigencia de {/* INSERTAR CONDICIONAL PARA DETERMINAR SI SON MESES O AÑOS O MESES Y AÑOS */} {answers.filter(answer => answer.id === 6).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })};  meses/años contados a partir del día {answers.filter(answer => answer.id === 3).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}; y finalizando el {/* INSERTAR FUNCION PARA HACER EL CALCULO DE LA FECHA DE FINALIZACIÓN */}

        </p>

        <p>
        Presente lo anterior, los contratantes acuerdan que la presente relación de arrendamiento concluye en el día prefijado sin necesidad de desahucio en concordancia con lo establecido dentro del <strong>artículo  2358 del Código Civil de Baja California. </strong>. 
        </p>

        {/* TERCERO.- RENOVACIÓN */}
        <p>
          <strong>TERCERO.- RENOVACIÓN.</strong> <br/>
          Vencido el plazo del arrendamiento, no se entenderá prorrogado sino mediante contrato expreso y escrito. Presente lo anterior, si el <strong>“ARRENDATARIO” tiene interés en prolongar la vigencia del presente instrumento, o bien, generar uno nuevo,</strong> deberá 
          solicitar la prórroga por escrito al <strong>“ARRENDADOR”</strong> mediante notificación efectuada con al menos (1) mes de anticipación al término de la vigencia del Contrato, en el domicilio señalado por el mismo, por lo que el <strong>“ARRENDATARIO”</strong> renuncia 
          en este mismo acto al derecho de prórroga previsto dentro del artículo 2359 del Código Civil vigente para el Estado de Baja California, quedando por ende la prórroga o la celebración de un contrato alterno al escrutinio del<strong>“ARRENDADOR”</strong>.

        </p>

        {/* CUARTA.- RENTA */}
        <p>
        <strong>CUARTA.- RENTA.</strong> <br/>
        Las Partes convienen en que el pago por concepto de <strong>“Renta”</strong> que deberá pagar el <strong>“ARRENDATARIO”</strong> a favor del <strong>"ARRENDADOR"</strong> será realizado de manera <strong>MENSUAL debiéndose pagar</strong> la cantidad de 
        <strong>$ {answers.filter(answer => answer.id === 7).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}; Pesos/Dólares dentro de los primeros {answers.filter(answer => answer.id === 10).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}; días de cada mes</strong>,  a través de cualquiera de los siguientes medios:

        </p>

        {/* INSERTAR FUNCIÓN PARA ANEXAR CONDDICIONAL EN CASO DE SOLICITAR AUNMENTO DE LA RENTA */}

        <p>
        <strong>a).-Transferencia electrónica de fondos  a la tarjeta identificada con el Número "{/* INSERTAR PARAMETRO DE NÚMERO DE CUENTA*/}" del banco  "{/* INSERTAR PARAMETRO DE BANCO */}"; o bien, deposito </strong> a la cuenta bancaria identificada con el No 
        {/* INSERTAR PARAMERTRO DE NUMERO DE CUENTA BANCARIA */}, cuenta  CLABE {/* INSERTAR PARAMETRO DE CLABE */}, del banco denominada "{/* INSERTAR PARAMTRO DE NOMBRE DE BANCO */}", conviniendo las partes en que la constancia electrónica del movimiento, o bien 
        el recibo emitido a razón del depósito, hará las veces del recibo más amplio que en derecho proceda.
            <br/><br/>

            b).-En efectivo dentro de la siguiente dirección “________________”, comprometiéndose el “ARRENDADOR” a emitir el recibo más amplio y eficaz que en derecho proceda, debiendo contener los siguientes datos:
            <br/><br/>
            I. Concepto.
            II. Fecha.
            III. Monto pagado.
            IV. Monto adeudado (En caso de que no se cubra en su totalidad el monto del mes, sin que por ello se entienda que pueda abonar sin que se verifiquen intereses).
            V. Mes que cubre dicho pago.
            VI. Firma autógrafa del <strong>ARRENDADOR</strong> o de la persona que éste designe por escrito.
            <br/><br/>

            <strong>CUARTA.- DE LA NO DISMINUCIÓN DE RENTA</strong> <br/>
            <strong>El  “ARRENDATARIO”</strong> por ningún motivo podrá solicitar o exigir la disminución de la renta pactada, por lo que expresamente renuncia a lo dispuesto en el artículos 2295 del código civil vigente en el Estado de Baja California.
            <br/><br/>
            Lo anterior, sin perjuicio de lo establecido dentro de los artículos 2305, 2306 y 2307 del multicitado Código Civil de Baja California. 
        </p>

         {/* INSERTAR CONDICIONAL PARA INTEGRAR QUINTA CLAUSULA */}
        
        {/* QUINTA.- INTERESES MORATORIOS */}
        {/* <p>
        <strong>QUINTA.- INTERESES MORATORIOS.-</strong><br/>
        En caso de  que el <strong>“ARRENDATARIO”</strong> sea omiso en realizar el pago de renta mensual dentro de los primeros {answers.filter(answer => answer.id === 10).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })}; días del mes correspondiente, el <strong>“ARRENDATARIO”</strong> deberá pagar intereses moratorios que serán calculados de la siguiente manera: 
        <br/><br/>
        Por cada mensualidad que no sea pagada dentro de los ____ días de cada mes, se aplicará una TASA MENSUAL del ____% (________) al monto que representa la última parcialidad vencida que se seguirá verificando en tanto no se cubra el adeudo.
        <br/><b/>
        En caso de que el ARRENDATARIO haya realizado un pago parcial, se aplicará la TASA MENSUAL de interés previamente mencionada (___%) sobre saldos insolutos
        <br/><br/>
        Además de lo anterior, las partes convienen en que todo pago realizado con posterioridad a la verificación de los intereses moratorios, los mismos serán acreditados de conformidad a la siguiente prelación: 
        <br/><br/>
        <strong>  
        1.- Abono a intereses <br/>
        2.- Abono a capital (monto principal de la renta adeudada)
        </strong>

        </p> */}

      {/* SEXTA.-DEPÓSITO */}
      <p>
      <strong>SEXTA.-DEPÓSITO.</strong> <br/>
      En este mismo acto, el <strong>“ARRENDATARIO”</strong> entrega un depósito a favor del <strong>“ARRENDADOR”</strong> por un importe de  <strong>$ {answers.filter(answer => answer.id === 8).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })};.</strong>
        <br/><br/>
        Presente lo anterior, las partes acuerdan que el depósito en comento no podrá ser acreditado para el pago de rentas que en algún momento puedan llegar a ser omitidas por el <strong>“ARRENDATARIO”</strong>, ya que el mismo fue creado para garantizar 
        que la propiedad arrendada sea devuelta en buen estado, así como para ser empleado en caso de existir algún adeudo pendiente para con aquellos entes que presten servicios al <strong>“INMUEBLE”</strong>.
        <br/><br/>
        Dicho depósito será devuelto a <strong>“EL  ARRENDATARIO” 10 DÍAS hábiles</strong> después de la entrega del <strong>“INMUEBLE”</strong>, siempre que éste no  presentare daño alguno, esté completo el inventario y no existan adeudos por servicios en dicha fecha. 
        <br/><br/>
        En caso contrario, dicha suma la aplicará <strong>“EL ARRENDADOR”</strong> para  hacer el pago de dichos adeudos, así como de las obras de reparación que se hicieran  necesarias, en la inteligencia de que <strong>“EL  ARRENDATARIO”</strong> tendrá derecho de que se 
        le exhiban los comprobantes de pago de que  se trate, según proceda, así como la devolución del remanente que sea a su favor. 
        <br/><br/>
        A su vez, las partes convienen en que la suma del depósito aumentará proporcionalmente al momento en que el pago de renta aumente conforme a la cláusula <strong>CUARTA</strong>, por lo que el <strong>“ARRENDATARIO”</strong> deberá de entregar las sumas necesarias 
        para que haya identidad entre el monto a pagar por renta mensual y el depósito en comento.

      </p>

      {/* SÉPTIMA .- SERVICIOS */}  
      <p>
      <strong>SÉPTIMA .- SERVICIOS.</strong> <br/>
      Es de común acuerdo el que todos aquellos gastos necesarios por concepto de Servicios básicos, como lo son el servicio de agua, luz, telefonía e internet, correrán por cuenta del <strong>“ARRENDATARIO”</strong>, debiendo cumplir diligentemente el entero de las obligaciones en tiempo y forma, de tal manera que al finalizar 
      la vigencia del presente contrato, no exista adeudo.
      </p>
      {/* OCTAVA.- MEJORAS */}  
      <p>
      <strong>OCTAVA.- MEJORAS.</strong> <br/>
      I).- Acuerdan las Partes que, en caso de estimarse como necesarias, el <strong>“ARRENDATARIO”</strong> podrá solicitar al <strong>“ARRENDADOR”</strong> permiso, a través de un escrito, para efectuar mejoras o cambios al <strong>“INMUEBLE”</strong> dado en arrendamiento; mismas que, salvo pacto 
      escrito y firmado ante dos testigos que manifieste lo contrario, permanecerán en favor del “INMUEBLE”. 
      <br/><br/>
      II).- Correrá por cuenta del <strong>“ARRENDATARIO”</strong> el hacer las reparaciones de todos aquellos deterioros que cause, así como todos aquellos daños que se deriven por su culpa, omisión o negligencia. Las partes acuerdan que, todas las reparaciones y/o mejoras que el <strong>“ARRENDATARIO”</strong> realice al <strong>“INMUEBLE”</strong>, 
      ya sean útiles, necesarias o de ornato, y salvo pacto escrito en contrario, quedarán a beneficio del mismo, dejando sin derecho a el <strong>"ARRENDATARIO”</strong> para cobrar traspaso o indemnización alguna.
      </p>

      {/* NOVENA.- REPARACIONES A CARGO DEL ARRENDADOR: */}  
      <p>
      <strong>NOVENA.- REPARACIONES A CARGO DEL ARRENDADOR:</strong> <br/>
      El <strong>“ARRENDADOR”</strong>, se obliga a  conservar el <strong>“INMUEBLE”</strong> en el mismo estado funcional durante todo el arrendamiento, haciendo para ello todas las reparaciones indispensables o necesarias siguientes: 
      <br/><br/>
      A) El cambio de las tuberías que produzcan filtraciones por ser muy viejas.<br/>
      B) La aplicación de impermeabilizante en el inmueble.<br/>
      C) Conservación de instalaciones eléctricas.<br/>
      D) Conservación de instalaciones sanitarias, siempre y cuando el deterioro haya sido generado por el desgaste natural del bien.<br/>
      E) Reparación de los deterioros causados a los  cimientos del INMUEBLE que se ocasionen por el desgaste natural del inmueble. 
      <br></br>
      Delimitadas las obligaciones de conservación a cargo del ARRENDADOR, las partes convienen en que el “ARRENDATARIO” deberá de notificar por cualquier medio que deje prueba fehaciente de la comunicación, aquellas reparaciones que deba de realizar el “ARRENDADOR” en virtud del listado anterior, en un término no mayor 
      3 (tres) días contados a partir de que el primero haya obtenido conocimiento del desperfecto.
      <br></br>
      Por otra parte, los contratantes reiteran que todas aquellas adecuaciones o composturas superficiales, como lo pueden ser el arreglo de mobiliario, resane y pintado de paredes, compostura del piso, arreglo de ventanas y/o cualquier otro tipo de arreglo superficial que no tenga  una injerencia negativa en la estructura del INMUEBLE al grado 
      de poner en peligro la estancia del “ARRENDATARIO” dentro del mismo, deberá ser cubierta por el ARRENDATARIO sin que este tenga derecho a exigir compensación o indemnización alguna.
      </p>

      {/* DÉCIMA.- LAS PARTES ACUERDAN COMO CAUSALES DE RESCISIÓN DE CONTRATO IMPUTABLES AL “ARRENDATARIO” LAS SIGUIENTES: */}  
      <p>
      <strong>DÉCIMA.- LAS PARTES ACUERDAN COMO CAUSALES DE RESCISIÓN DE CONTRATO IMPUTABLES AL “ARRENDATARIO” LAS SIGUIENTES:</strong> <br/>

      A) La falta de pago de dos meses de renta consecutivos. <br/>
      B) No pagar los intereses que en su caso se hayan acumulado en un lapso mayor de 2 (dos) meses. <br/>
      C) No pagar los servicios de agua, luz o cualquier otro análogo que pueda generar deudas al “ARRENDADOR”. <br/>
      D) Pretender transmitir el Uso del INMUEBLE. <br/>
      E) Hacer obras o modificaciones a los INMUEBLES, sin previa autorización por escrito del  “ARRENDADOR”, cuando las mismas deban de ser notificadas. <br/>
      F) Cualquier contravención a lo estipulado en este contrato. <br/>
      G) Retener parcial o totalmente alguna renta. <br/>
      H) Pretender otorgar el uso del “INMUEBLE” sin autorización. <br/> {/* AGREGAR FUNCION DINAMICA DE RENDERIZAO EN EL TEXTO */}

      </p>

      {/* DÉCIMA PRIMERA. - “EL ARRENDATARIO” */}
      <p>
      <strong>DÉCIMA PRIMERA. - “EL ARRENDATARIO”</strong> en este acto, renuncia al derecho establecido en el artículo 2359 del código civil vigente en el estado de Baja California, por lo que, de la misma manera, las partes acuerdan que no habrá lugar a la tacita reconducción, por lo que cualquier prórroga que se pacte, 
      deberá de constar por escrito.
      </p>

      {/* DÉCIMA SEGUNDA */}
      <p>
      <strong>DÉCIMA SEGUNDA.-</strong> Las partes acuerdan que el <strong>“ARRENDATARIO”</strong> se encuentra facultado para SUBARRENDAR el <strong>“INMUEBLE”</strong>, constituyéndose la presente cláusula como la autorización general para subarrendar prevista en el artículo 2355 del Código Civil Vigente para el 
      Estado de Baja California; no necesitando entonces ninguna autorización posterior por parte del <strong>“ARRENDADORA”</strong> para el efecto de dar en subarrendamiento el <strong>“INMUEBLE”</strong>. 
      </p>


      <p>
      <strong>DÉCIMA TERCERA. - UTILIZACIÓN DE LOS INMUEBLES PARA FINES ILÍCITOS DURANTE LA VIGENCIA DE ESTE CONTRATO Y DEL JUICIO DE EXTINCIÓN DE DOMINIO</strong>. El <strong>“ARRENDATARIO”</strong> reitera y acepta que  le queda prohibido el uso del inmueble arrendado para fines ilícitos, por lo que desde este 
      momento el <strong>“ARRENDATARIO”</strong> exonera al <strong>“ARRENDADOR”</strong> de cualquier responsabilidad legal, derivada del uso ilícito que se le hubiese dado o que se le estuviese dando al <strong>INMUEBLE</strong> durante la vigencia del presente contrato y sus prórrogas, realizando el <strong>ARRENDATARIO</strong> 
      a su vez las siguientes manifestaciones:

      <br/><br/>

      A). Acepta <strong>“EL ARRENDATARIO”</strong> que si durante la vigencia de este contrato, el inmueble objeto del mismo, fuese destinado por el propio <strong>ARRENDATARIO</strong>, y/o cualquiera de las personas físicas que la componen o que trabajen para la misma en el inmueble arrendado, para un fin que la autoridad 
      competente estimase ilícito, y para el caso de que dicha autoridad o cualquier otra, por ese motivo, iniciare un juicio autónomo de Extinción de Dominio, respecto del inmueble, y a <strong>“EL ARRENDATARIO”</strong> o a cualquiera de las personas físicas que la componen o que trabajen para la misma en el inmueble arrendado 
      se les considerará como la o los responsables, salvo prueba en contrario, del fin que se estime ilícito que se le hubiese dado a dicho inmueble durante la vigencia de este contrato de arrendamiento y /o durante la ocupación del inmueble por <strong>“EL ARRENDATARIO”</strong>, esta última exonera desde este momento de 
      cualquier responsabilidad dentro del citado juicio, desde luego, salvo prueba en contrario, tanto a <strong>“EL ARRENDADOR”</strong> del inmueble como al agente o corredor inmobiliario que hubiese sido el intermediario entre el citado <strong>“EL ARRENDADOR”</strong> y <strong>“EL ARRENDATARIO”</strong>.

      <br/><br/>

      B). Acepta <strong>“EL ARRENDATARIO”</strong> que si durante la vigencia de este contrato de arrendamiento tuviese conocimiento de que sin su consentimiento, se estuviesen cometiendo actos que estimare ilícitos en el inmueble, se obliga a poner de inmediato del conocimiento tanto de las autoridades ministeriales y/o penales 
      correspondientes, como a <strong>“EL ARRENDADOR”</strong> así como al corredor o agente inmobiliario que le estuviese administrando las rentas del inmueble a aquel, respecto de la comisión de actos que estima ilícitos.

      <br/><br/>

      C). Acepta <strong>“EL ARRENDATARIO”</strong> que para el caso de que el inmueble arrendado se viese involucrado durante la vigencia de este contrato de arrendamiento, en actos ilícitos, y estos se estimasen responsabilidad de <strong>“EL ARRENDATARIO”</strong>, y derivado de ello, se iniciase un juicio de Extinción de Dominio, 
      y por virtud de la sentencia dictada en el mismo, operase esa acción legal sobre dicho inmueble a favor del Estado, <strong>“EL ARRENDATARIO”</strong> y/o su Fiador, se obligan desde este momento, a pagar en forma conjunta o por separado a <strong>“EL ARRENDADOR”</strong>, el valor total del inmueble, pago que se deberá realizar 
      a partir de la fecha en que se haya decretado en forma definitiva la Extinción de Dominio a favor del Estado Mexicano y en perjuicio de <strong>“EL ARRENDADOR”</strong> o del propietario. El precio que se tomará en cuenta para cumplir con esta obligación, será el valor que tenga el inmueble objeto de este contrato en el mercado 
      inmobiliario al momento del pago.

      <br/><br/>

      D). Acepta <strong>“EL ARRENDADOR”</strong> que si durante la vigencia de este contrato, la autoridad competente advirtiese que el inmueble, previo a la celebración de este contrato, hubiese sido destinado para un fin que se estimase ilícito, y para el caso de que dicha autoridad o cualquier otra, iniciare un juicio autónomo de 
      Extinción de Dominio, respecto del inmueble, y a <strong>“EL ARRENDADOR”</strong> o a cualquier otra persona distinta a <strong>“EL ARRENDATARIO”</strong> se le considerara como la responsable, salvo prueba en contrario, del fin que se estime ilícito, desde este momento <strong>“EL ARRENDADOR”</strong> exonera 
      a <strong>“EL ARRENDATARIO”</strong> de cualquier responsabilidad dentro del citado juicio de Extinción de Dominio, desde luego, salvo prueba en contrario, y exonera de la misma manera al agente o corredor inmobiliario que hubiese sido el intermediario entre <strong>“EL ARRENDADOR”</strong> del inmueble y 
      <strong>“EL ARRENDATARIO”</strong>. Derivado de lo anterior, <strong>“EL ARRENDADOR”</strong> se obliga a resarcir a <strong>“EL ARRENDATARIO”</strong>, respecto de los daños y perjuicios que se le ocasionaren con motivo de la substanciación del citado Juicio de Extinción de Dominio.

      <br/><br/>

      E). Acepta <strong>“EL ARRENDADOR”</strong> que si durante la vigencia de este contrato, la autoridad competente advirtiese que el inmueble, previo a la celebración de este contrato, hubiese sido destinado para un fin que se estimase ilícito y que por virtud de ello se iniciare cualquier acción penal contra persona distinta 
      a <strong>“EL ARRENDATARIO”</strong>, y se considerará como culpable de la comisión de cualquier delito por el uso ilícito del inmueble que se le hubiese dado al inmueble antes de la celebración de este contrato, desde este momento <strong>“EL ARRENDADOR”</strong> exonera a <strong>“EL ARRENDATARIO”</strong> de cualquier 
      responsabilidad dentro de cualquiera de los juicios penales que se llegasen a instaurar, desde luego, salvo prueba en contrario, y exonera de la misma manera al agente o corredor inmobiliario que hubiese sido el intermediario entre <strong>“EL ARRENDADOR”</strong> del inmueble y <strong>“EL ARRENDATARIO”</strong>.
      </p>

      {/* INSERTA FUNCION DINAMICA PARA SERLECCIONAR LA CLAUSULA DECIMA SEGUNDA */}

      {/* DÉCIMA SEGUNDA SIN AVAL */}
      <p>
      <strong>DÉCIMA SEGUNDA.-</strong> El presente contrato de  arrendamiento será automáticamente rescindido por la muerte del <strong>“ARRENDADOR”</strong>.
      </p> 

      {/* DÉCIMA SEGUNDA CON AVAL */}
      
      {/* <p>
      <strong>DÉCIMA SEGUNDA.- OBLIGADO SOLIDARIO.</strong> Concurre a este acto, ____________ en lo personal con el carácter de <strong>OBLIGADO SOLIDARIO</strong> de las obligaciones contraídas por  el <strong>"ARRENDATARIO”</strong>, manifestando ser una persona de reconocida solvencia moral y económica, y que reconoce las 
      obligaciones que contrae a favor del <strong>"ARREDADOR”</strong> al firmar el presente contrato; manifestando estar de acuerdo en que seguirá siendo el OBLIGADO SOLIDARIO aún en el caso de que el arrendamiento, por cualquier causa, se prolongue más allá del término fijado en el presente  instrumento.

      Por otra parte, y aunque no es fiador si no obligado solidario, desde este momento renuncia a los beneficios de orden, excusión y división, a que se refieren los artículos 2687, 2688 y 2711 del Código Civil para el Estado de Baja California, declarando bajo protesta de decir verdad que cuenta con bienes sufiientes para 
      que en caso de ser necesario, el <strong>"ARRENDADOR"</strong> pueda realizar el cobro efectivo de las obligaciones aquí contraidas por el <strong>"ARRENDATARIO"</strong>; bienes tales como los enunciados dentro del listado adjunto al presente, bajo título <strong>"ANEXO DEL OBLIGADO SOLIDARIO"</strong>, misma lista que 
      forma parte del presente contrato.
      </p> */}

      <p>
      <strong>DÉCIMA TERCERA.</strong> - Las partes acuerdan que cualquier modificación al presente contrato deberá de constar por escrito y firmado por las partes, es decir, se deberá de elaborar el convenio respectivo en el que se plasmen y acuerden las condiciones, derechos y obligaciones que se modifican, por lo que no se 
      reconocerá cualquier estipulación hecha en contravención a lo anterior.

      <br/><br/>
      Todas las partes que comparecen al presente contrato, lo hacen sin que exista mala fe, dolo, error, lesión o cualquier otro vicio del consentimiento, sino que por lo contrario, se encuentran perfectamente conscientes de los derechos y obligaciones que aquí se adquieren y de común acuerdo lo firman en esta ciudad de
       _____, Baja California, a los _______ días del mes de ____ del ____
      </p>

      <br/><br/>

      <center>
      <p>
      ___________________________ <br/>
      ARRENDADOR<br/>
      <strong>{answers.filter(answer => answer.id === 1).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })};</strong>

      </p>
      </center>

      <br/><br/>

      <center>
      <p>
      ___________________________ <br/>
      ARRENDATARIO<br/>
      <strong>{answers.filter(answer => answer.id === 2).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })};</strong>

      </p>
      </center>

      <br/><br/>

      <center>
      <p>
      ___________________________ <br/>
      TESTIGO #1<br/>
      <strong>{answers.filter(answer => answer.id === 12).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })};</strong>

      </p>
      </center>

      <br/><br/>

      <center>
      <p>
      ___________________________ <br/>
      TESTIGO #2<br/>
      <strong>{answers.filter(answer => answer.id === 13).map((answer) => {
          return (

            <strong>{answer.answer}</strong>
          );
        })};</strong>

      </p>
      </center>

      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <button className={classes.buttonBuildNew} onClick={refreshPage}>
          Iniciar nuevo contrato
        </button>
        <Pdf targetRef={ref} filename="contract.pdf">
          {({ toPdf }) => (
            <button onClick={toPdf} className={classes.buttonDownload}>
              Descargar contrato
            </button>
          )}
        </Pdf>
      </div>
    </div>
  );
}

export default Contract;
