import React, { useContext } from "react";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AppContext from "../AppContext";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
//import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: "block",
    marginTop: "1rem",
  },
  button: {
    background: "white",
  },
}));

function Question() {
  const classes = useStyles();
  const value = useContext(AppContext);

  // Back Button
  

  //Save the answer. 
  let { questionAnswer } = value.state;

  //Next question. 
  let { handleChangeInput, nextQuestion } = value.function;
  // let { handleChangeInput, nextQuestion, previewQuestion } = value.function;
  //Next question. 


  return (
    <div>
    {/* // Form Container */}
      <form className=' question_container ' autoComplete="on" onSubmit={nextQuestion}>
        <TextField
          id="textField"
          // id={questionAnswer.id}
          label={questionAnswer.question}
          name={questionAnswer.contractFieldId}
          value={questionAnswer.answer ? questionAnswer.answer : ""}
          onChange={handleChangeInput}
          type={questionAnswer.type}
          required
          
        />
        <div className=' question_container__button '>


        {/* Back Button */}
        <div className='question_container__button-left'>
          <Button
          //  onClick={previewQuestion}>
            variant="contained"
            style={{
            
                backgroundColor: "#517a9a",
                color: "#ffffff",
                marginRight: "1rem"
    
            }}
            className="question_container__btn"
            startIcon={<ArrowLeft />}
          >
            Retroceder
          </Button>
        </div>
          
        {/*  Next Button  */}
        <div className='question_container__button-right'>
          <Button
            type="submit"
            variant="contained"
            style={{
              
                backgroundColor: "#223554",
                color: "#ffffff",
                marginRight: "1rem"

            }}
            className="question_container__btn"
            endIcon={<ArrowRight />}
          >
            Continuar
          </Button>
        </div>
          

          
        </div>
      </form>
    </div>
  );
}
export default Question;
